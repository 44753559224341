import React from "react"
import SEO from "../components/seo"
import GlobalStyle from "../components/globalStyle"
import styled from "styled-components"
import Hero from "../components/hero"

import P5 from "../components/p5/p5_4/p5"

const Container = styled.div``
const MinimaLineGeneration = () => (
  <Container>
    <SEO title="Minimal line generation" />
    <GlobalStyle />
    <Hero />
    <P5 />
  </Container>
)

export default MinimaLineGeneration
