export default function Sketch(p5) {
    let canvas;
    let x = 0;
    let y = 0;

    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);
        p5.noStroke();
        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        for (let i = 0; i < 1500; i += 200) {
            p5.stroke(255)
            p5.line(i + x, y, 0, p5.height)

        }
        for (let i = 0; i < 150; i += 20) {
            p5.stroke(255)
            p5.line(i + i + x, y, 0, p5.height)

        }
        x = x + 1;

    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }


}





